/*-- Dark Mode Switcher --*/
const themeSwitcher = document.querySelector('.theme--switcher input');
const currentTheme = localStorage.getItem('theme');
if (currentTheme) {
  document.documentElement.setAttribute('data-theme', currentTheme);
  if (currentTheme === 'light') {
    themeSwitcher.checked = true;
  }
}
const switchTheme = (event) => {
  const isDarkMode = event.target.checked;
  const newTheme = isDarkMode ? 'dark' : 'light';
  document.documentElement.setAttribute('data-theme', newTheme);
  localStorage.setItem('theme', newTheme);
};
themeSwitcher?.addEventListener('change', switchTheme);

/*-- Lazy Loading Images --*/
var lazy = document.getElementsByClassName('lazy');
for(var i=0; i<lazy.length; i++){
 lazy[i].src = lazy[i].getAttribute('data-src');
}

/*-- Animations --*/
var observer = new IntersectionObserver(
  function (entries) {
    entries.forEach(function (entry) {
      var el = entry.target;
      if (entry.isIntersecting) {
        el.classList.add('animate');
        return;
      }
    });
  },
  {threshold: 0.2}
);
document.querySelectorAll('.animation').forEach(function (i) {
  if (i) {
    observer.observe(i);
  }
});
